import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Parcel } from '../../../types/shipment';
import { ParcelTable } from './ParcelsTable';

type ParcelsSectionProps = {
  data: Parcel[];
};

export const ParcelsSection: React.FC<ParcelsSectionProps> = ({ data }) => {
  /** Tabs view of parcels with basic data and tracking */

  return (
    <Tabs variant="soft-rounded" colorScheme="blue">
      <TabList
        overflowY="hidden"
        sx={
          {
            // scrollbarWidth: 'none',
            // '::-webkit-scrollbar': {
            //   display: 'none',
            // },
          }
        }
      >
        {data.map((parcel) => (
          <Tab key={`${parcel.id}_tab`}>{parcel.referenceNumber}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {data.map((parcel) => (
          <TabPanel key={`${parcel.id}_panel`}>
            <ParcelTable parcel={parcel} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
